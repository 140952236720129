import { mapGetters } from "vuex";
import * as d3 from "d3";

export default {
  computed: {
    ...mapGetters(["newDataPoints"]),
  },
  methods: {
    getWeekData(dataset) {
      // change to current actual day on live
      let today = new Date();
      let weekAgo = new Date();
      weekAgo.setDate(today.getDate() - 7);

      return this.filterDataToDates(weekAgo, dataset);
    },
    getMonthData(dataset) {
      // change to current actual day on live
      let today = new Date();
      let monthAgo = new Date();
      monthAgo.setDate(today.getDate() - 30);

      return this.filterDataToDates(monthAgo, dataset);
    },
    get6MonthsData(dataset) {
      // change to current actual day on live
      let today = new Date();
      let sixMonthsAgo = new Date();
      sixMonthsAgo.setDate(today.getDate() - 180);

      return this.filterDataToDates(sixMonthsAgo, dataset);
    },
    getYTDData(dataset) {
      let startOfYear = new Date(new Date().getFullYear(), 0, 1);

      return this.filterDataToDates(startOfYear, dataset);
    },
    filterDataToDates(firstDay, dataset) {
      const targetDay = new Date(firstDay);
      const filteredDataset = dataset.filter(d => {
        const currentDate = new Date(d.date);
        return currentDate >= targetDay;
      });
      return filteredDataset;
    },
    processData(dataset, catID = 0) {
      let arr = [];

			for(let k in dataset.n){
				let v = dataset.n[k]
				arr.push({
						date: new Date(k),
						value: +v[catID].toFixed(2)
				})
			}

      return arr;
    },
    filterData(dataset, offset, catID = 0){

      let arr = this.processData(dataset, catID);

      if((offset||0)===0)
        return arr

      const ret = []

      if(offset === -1){
          const year = "" + (new Date).getUTCFullYear()

          for(let n=0; n<arr.length; ++n){
              const v=arr[n]
              
              if(v.date.getUTCFullYear() == year)
                ret.push(v)
          }
      } else {
        for(let n=arr.length-offset; n < arr.length; ++n) {
          ret.push(arr[n])
        }        
      }

      return ret;
    },
    addChartHover(svg, g, x, y, data, width = 730, height = 300, margin) {
      let bisectDate = d3.bisector(function(d) { return d.date; }).left;
      let dateFormat = d3.timeFormat("%B %d, %Y");
			
			let focus = svg.append("g")
					.attr("class", "focus")
					.style("display", "none");

			focus.append("circle")
				.attr("r", 5);

			focus.append("rect")
				.attr("class", "tooltip")
				.attr("width", 120)
				.attr("height", 50)
				.attr("x", 10)
				.attr("y", -22)
				.attr("rx", 4)
				.attr("ry", 4);

			focus.append("text")
				.attr("class", "tooltip-date")
				.attr("x", 18)
				.attr("y", 18);

			focus.append("text")
				.attr("class", "tooltip-trufrate")
				.attr("x", 18)
				.attr("y", -2);

			g.append("rect")
				.attr("class", "overlay")
				.attr("width", width)
				.attr("height", height)
				.on("mouseover", function() { focus.style("display", null); })
				.on("mouseout", function() { focus.style("display", "none"); })
				.on("mousemove", mousemove);

      function mousemove(event) {
        var x0 = x.invert(d3.pointer(event)[0]),
            i = bisectDate(data, x0, 1),
            d0 = data[i - 1],
            d1 = data[i],
            d = x0 - d0.date > d1.date - x0 ? d1 : d0;
        
        focus.attr("transform", "translate(" + (x(d.date) + margin.left) + "," + (y(d.value) + margin.top) + ")");
        const tooltipDate = focus.select(".tooltip-date");
        tooltipDate.text(dateFormat(d.date));
        focus.select(".tooltip-trufrate").text(d.value + '%');
        focus.select(".tooltip").attr("width", tooltipDate._groups[0][0].getComputedTextLength() + 15);
        
      }
    },
  }
}