<template lang="pug">
	.cat-timeframe
		.cat-timeframe__sidebar
			h3 Category
			button.mobile-only.cat-timeframe_mobile_filter(v-if="activeCategoryData !== null", :data-id="activeCategoryData.id", @click="openCategories", :class="{'is-active': mobileCatActive }")
				span.bg-color(:class="`bg-color--${activeCategoryData.color}`")
				| {{ activeCategoryData.name }}
				i.arrow
			ul.cat-timeframe__filters(:class="{'is-active': mobileCatActive }")
				li(v-for="(cat, i) in categories", :class="{'desktop-only': i == activeCategoryID}")
					button.cat-timeframe__filters_btn(:data-id="i", :class="{active: i == activeCategoryID}", @click="selectCategory(i)")
						span.bg-color(:class="`bg-color--${cat.color}`")
						| {{ cat.name }}
						
		.cat-timeframe__growth
			.cat-timeframe__growth_heading
				h3 Category Growth vs Timeframe
				//- button.tooltip(ref="tooltip")
				//- 	span.sr-only lorem ipsum
				//- 	i.icon.icon-info
			.cat-timeframe__growth_wrapper 
				GrowthDisplayComponent(label="Category YoY Rate" :value="currentTruflationRate" :isActive="true")
				GrowthDisplayComponent(label="vs Yesterday" :value="yesterdayTruflationRate" :isActive="false")
				GrowthDisplayComponent(label="vs a Week ago" :value="weekTruflationRate" :isActive="false")
				GrowthDisplayComponent(label="vs a Month ago" :value="monthTruflationRate" :isActive="false")
				GrowthDisplayComponent(label="vs a Quarter ago" :value="quarterTruflationRate" :isActive="false")

		.cat-timeframe__trend
			.cat-timeframe__trend_heading
				h3 Category Inflation Rate Trend
				.cat-timeframe__date_filters_wrap
					ul.cat-timeframe__date_filters.timeframe-filters
						li
							button.filter-btn.timeframe-filters__filter_btn(
								@click="setChartData('week')", 
								:class="{active: activeChart === 'week'}") 1W
						li
							button.filter-btn.timeframe-filters__filter_btn(
								@click="setChartData('month')", 
								:class="{active: activeChart === 'month'}") 1M
						li
							button.filter-btn.timeframe-filters__filter_btn(
								@click="setChartData('six-months')", 
								:class="{active: activeChart === 'six-months'}") 6M
						li
							button.filter-btn.timeframe-filters__filter_btn(
								@click="setChartData('year-to-date')", 
								:class="{active: activeChart === 'year-to-date'}") YTD
						li
							button.filter-btn.timeframe-filters__filter_btn(
								@click="setChartData('year')", 
								:class="{active: activeChart === 'year'}") 1Y
					DateRangePicker(
						ref="picker"
						opens="left"
						:locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
						:minDate="minDate" :maxDate="maxDate"
						:timePicker24Hour=false
						:autoApply=false
						:ranges="ranges"
						v-model="dateRange"
						@update="updateValues"
						linkedCalendars=false
						:toggle="toggleDatePicker"
					)
						template(v-slot:input="picker" style="min-width: 350px;")
							p(v-if="picker.startDate === null") Pick a period
							p(v-else) {{ dateToString(picker.startDate) }} - {{ dateToString(picker.endDate) }}

			.cat-timeframe__trend_chart
				svg#cat-truf-rate
				p.dashboard_disclaimer *All times are UTC

		.cat-timeframe__info(v-if="activeCategoryData !== null")
			h3 Category Information
			.cat-timeframe__info_wrapper
				.cat-timeframe__info_pie
					h4 Relative Importance
					.pie-chart
						.c100(:class="`p${activeCategoryData.relative_importance.toFixed(0)}`")
							span {{ activeCategoryData.relative_importance.toFixed(2) }}%
							.slice
								.bar(:class="`border-color--${activeCategoryData.color}`")
								.fill
				.cat-timeframe__info_subcategories
					h4 Subcategories
					ul
						li(v-for="subcat in subcategories") {{ subcat }}
			
</template>
<script>
import * as d3 from "d3";
import GrowthDisplayComponent from './GrowthDisplay.vue';
import filterMixin from '../mixins/dataFilters.js';
// import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
	name: 'CatTimeframeComponent',
	mixins: [filterMixin],
	components: {
		GrowthDisplayComponent,
		DateRangePicker 
	},
	data() {
		return {
			chartData: [],
			categoryData: [],
			activeCategoryID: 0,
			activeCategoryData: null,
			currentTruflationRate: null,
			yesterdayTruflationRate: null,
			weekTruflationRate: null,
			monthTruflationRate: null,
			quarterTruflationRate: null,
			categories: null,
			subcategories: null,
			activeChart: 'week',
			categoryTruflationRates: [],
			mobileCatActive: false,
			catColors: ['yellow','orange','cat-blue','red','purple','blue-green', 'dark-green', 'moss-green','cream','violet','dark-blue','green'],
			dateRange: {
				startDate: null,
				endDate: null
			},
			ranges: false
		}
	},
	computed: {
		minDate() {
			const today = new Date();
			const yearAgo = new Date();
			yearAgo.setFullYear(today.getUTCFullYear() - 1);
			return yearAgo;
		},
		maxDate() {
			return new Date();
		}
  },
	watch: {
    chartData() {
      this.drawChart();
		},
		newDataPoints() {
			if(Object.keys(this.newDataPoints).length) {
				console.log(this.newDataPoints);
				this.setCategories();
				this.setCategoryData();
				this.setChartData();
			}
		},
		categories() {
			this.activeCategoryID = 0;
			this.activeCategoryData = this.categories[this.activeCategoryID];
		},
		activeCategoryID() {
			if(Object.keys(this.newDataPoints).length) {
				this.setCategoryData();
				this.activeCategoryData = this.categories[this.activeCategoryID];
				this.drawChart();
			}
		},
		activeCategoryData() {
			let subcategories = [];

			for(let i in this.activeCategoryData.names) {
				subcategories.push(this.activeCategoryData.names[i])
			}

			this.subcategories = subcategories;
		},
  },
  methods: {
		dateToString(selectedDate) {
			return ('0' + selectedDate.getUTCDate()).slice(-2) + '/' + ('0' + (selectedDate.getUTCMonth()+1)).slice(-2) + '/' + selectedDate.getUTCFullYear();
		},
		selectCategory(catID) {
			this.activeCategoryID = catID;
			this.mobileCatActive = false;
		},
		setCategories() {
			const c = this.newDataPoints.c;
			const categories = [];

			for(let i in c) {
				
				let j = this.catColors.length > i ? i : i - this.catColors.length;

				categories.push({
					...c[i],
					color: this.catColors[j]
				});
			}

			this.categories = categories;
		},
		setCategoryData() {
			const d = this.newDataPoints.d;
			this.currentTruflationRate = d.today[this.activeCategoryID];
			this.yesterdayTruflationRate = d.yesterday[this.activeCategoryID];
			this.weekTruflationRate = d.lastWeek[this.activeCategoryID];
			this.monthTruflationRate = d.lastMonth[this.activeCategoryID];
			this.quarterTruflationRate = d.lastQuarter[this.activeCategoryID];
		},
		drawChart() {

			if(this.chartData.length === 0) {
				return false;
			}

			// set d3 chart values
			let data = this.chartData[this.activeCategoryID];
			let svgWidth = 800, svgHeight = 500;
			let margin = { top: 20, right: 0, bottom: 30, left: 32 };
			let width = svgWidth - margin.left - margin.right;
			let height = svgHeight - margin.top - margin.bottom;

			// initialize svg chart
			let svg = d3.select('#cat-truf-rate').attr("viewBox", `0 0 800 500`);
			svg.selectAll("*").remove();
			svg.attr("preserveAspectRatio", "xMinYMin meet");
			
			let g = svg.append("g")
					.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

			// set x & y axis 
			let x = d3.scaleTime()
					.rangeRound([0, width]);

			let y = d3.scaleLinear()
					.rangeRound([height, 0]);

			// extract max value from chart data for y axis
			let maxVal = Math.max(...this.chartData.map(dataset => Math.max(...dataset.map(d => d.value))));
			let minVal = Math.min(...this.chartData.map(dataset => Math.min(...dataset.map(d => d.value))));

			maxVal = Math.ceil(maxVal/5) * 5;
			minVal = Math.floor(minVal/5) * 5;

			x.domain(d3.extent(data, function(d) { return d.date }));
			y.domain([minVal, maxVal]);

			let xAxis = d3.axisBottom(x).tickSize(0).ticks(7);
			let yAxis = d3.axisLeft(y).tickSize(-width).ticks(5, "5f").tickFormat((d) => d + '%');

			// set up some conditional ticks
			if(this.activeChart === 'week') {
				// ticks are set every day for 1 week
				xAxis.ticks(d3.timeDay.every(1), '%b %e');
			} else if (this.activeChart === 'month') {
				// ticks are set every 3 days for 1 month, more spacing for each tick
				xAxis.ticks(d3.timeDay.filter(d=>d3.timeDay.count(0, d) % 3 === 0), '%b %e');
			} else if(this.activeChart === 'year-to-date') {

				// YTD data is more than 90 days, set x ticks to be per month
				data.length > 90 ?
					xAxis.ticks(d3.timeMonth.every(1), '%b') :
					xAxis.ticks(d3.timeDay.every(4), '%b %e');

			} else if(this.activeChart === 'six-months' || this.activeChart === 'year'){
				xAxis.ticks(d3.timeMonth.every(1), '%b');
			}

			g.append("g")
				.attr("class", "x-axis")
				.attr("transform", "translate(0," + (height + 10) + ")")
				.call(xAxis)
				.select(".domain")
				.remove();

			g.append("g")
				.attr("class", "y-axis")
				.attr("class", "y-axis")
				.call(yAxis)
				.append("text")
				.attr("fill", "#46608A")
				.attr("y", 6)
				.attr("dy", "0.71em")
				.attr("text-anchor", "end");

			const activeLineClass = `stroke--${this.categories[this.activeCategoryID].color}`;
			
			let line = d3.line()
				.x(function(d) { return x(d.date)})
				.y(function(d) { return y(+d.value)})

			g.append("path")
				.attr("class", activeLineClass)
				.datum(data)
					.attr("fill", "none")
					.attr("stroke", "#0D58C6")
					.attr("stroke-linejoin", "round")
					.attr("stroke-linecap", "round")
					.attr("stroke-width", 3)
					.attr("d", line);

			this.chartData.map((dataset, i) => {

				if(i !== this.activeCategoryID) {

					const catData = dataset;

					let newLine = d3.line()
						.x(function(d) { return x(d.date)})
						.y(function(d) { return y(+d.value)})

					const lineClass = `stroke--${this.categories[i].color}`;

					g.append("path")
						.attr("class", lineClass)
						.datum(catData)
							.attr("stroke-opacity", "0.3")
							.attr("fill", "none")
							.attr("stroke", "#0D58C6")
							.attr("stroke-linejoin", "round")
							.attr("stroke-linecap", "round")
							.attr("stroke-width", 1)
							.attr("d", newLine);
				}
			})

			this.addChartHover(svg, g, x, y, data, width, height, margin);

		},
		setCategoryTruflationRates() {
			let catData = [];

			this.categories.map(cat => {
				let sources = [];

				cat.subcategories.map(subcat => {
					if(subcat.sources !== undefined) {
						sources = sources.concat(this.getCategorySourceIDs(subcat.sources));
					}
				})

				const catTrufRates = this.getCategoryTrufRates(this.dataPoints, sources);

				catData.push({
					id: cat.id,
					name: cat.name,
					color: cat.color,
					truflationRates: catTrufRates
				})
			});

			this.categoryTruflationRates = catData;
		},
		getCategorySourceIDs(sourceArr) {
			let sourceIDs = [];
			sourceArr.map((d) => sourceIDs.push(d.id));
			return sourceIDs;
		},
		getCategoryTrufRates(dataset, sourceIds) {

			let categoryRates = dataset.map(rate => {

				// const currentDerivations = rate.derivations.atDate.filter(d => {
				const currentDerivations = rate.derivations.current.filter(d => {
					return sourceIds.includes(d.source_id)
				})
				const yearAgoDerivations = rate.derivations.yearAgo.filter(d => {
					return sourceIds.includes(d.source_id)
				})

				const adjustedInflationIndexSum = currentDerivations.reduce((acc, curr) => acc + parseFloat(curr.adjustedInflationIndex), 0)
				const relativeImportancesSum = currentDerivations.reduce((acc, curr) => acc + parseFloat(curr.relativeImportance), 0)
				const current = adjustedInflationIndexSum / relativeImportancesSum

				const yadjustedInflationIndexSum = yearAgoDerivations.reduce((acc, curr) => acc + parseFloat(curr.adjustedInflationIndex), 0)
				const yrelativeImportancesSum = yearAgoDerivations.reduce((acc, curr) => acc + parseFloat(curr.relativeImportance), 0)
				const yearAgo = yadjustedInflationIndexSum / yrelativeImportancesSum

				return {
					date: rate.date,
					current: current,
					yearAgo: yearAgo,
					value: current / yearAgo * 100 - 100
				}
			})

			return categoryRates;
		},
		filterChart2(range) {
			let organizedData = [];
			const selectedCategoryData = this.categoryTruflationRates.filter(d => {
				return d.id === this.activeCategoryID
			});
			this.categoryData = selectedCategoryData[0];

			this.categoryTruflationRates.forEach(dataset => {
				let catDataset = {
					id: dataset.id,
					name: dataset.name,
					color: dataset.color,
					data: []
				};

				let catTrufRates = []

				if(range === 'week') {
					catTrufRates = this.getWeekData(dataset.truflationRates);
					this.dateRange.startDate = null;
					this.dateRange.endDate = null;
					this.activeChart = 'week';
				} else if (range === 'month') {
					catTrufRates = this.getMonthData(dataset.truflationRates);
					this.dateRange.startDate = null;
					this.dateRange.endDate = null;
					this.activeChart = 'month';
				} else if (range === 'six-months') {
					catTrufRates = this.get6MonthsData(dataset.truflationRates);
					this.dateRange.startDate = null;
					this.dateRange.endDate = null;
					this.activeChart = 'six-months';
				} else if (range === 'year-to-date') {
					catTrufRates = this.getYTDData(dataset.truflationRates);
					this.dateRange.startDate = null;
					this.dateRange.endDate = null;
					this.activeChart = 'year-to-date'; 
				} else if (range === 'range') {
					catTrufRates = this.getRangeData(dataset.truflationRates);
					this.activeChart = 'range';
				} else {
					catTrufRates = dataset.truflationRates;
					this.dateRange.startDate = null;
					this.dateRange.endDate = null;
					this.activeChart = 'year';
				}

				const transformed = catTrufRates.map(d => ({
					date: new Date(d.date),
					value: +d.value.toFixed(2)
				}))

				catDataset.data = transformed;

				organizedData.push(catDataset);
			})

			this.chartData = organizedData;	
		},
		setChartData(range) {
			const catData = [];

			this.categories.forEach((val, i) => {
				const filteredData = this.filterChart(range, i);
				catData.push(filteredData);
			})

			this.chartData = catData;
		},
		filterChart(range, catID) {

			if(range === 'week') {
				this.activeChart = 'week';
				return this.filterData(this.newDataPoints, 7, catID + 1);
			} else if (range === 'month') {
				this.activeChart = 'month';
				return this.filterData(this.newDataPoints, 30, catID + 1);
			} else if (range === 'six-months') {
				this.activeChart = 'six-months';
				return this.filterData(this.newDataPoints, 180, catID + 1);
			} else if (range === 'year-to-date') {
				this.activeChart = 'year-to-date';
				return this.filterData(this.newDataPoints, -1, catID + 1);
			} else if (range === 'range') {
				this.activeChart = 'range';
				return this.getRangeData(this.newDataPoints, catID + 1);
			} else {
				this.activeChart = 'year';
				return this.filterData(this.newDataPoints, 0, catID + 1);
			}

		},
		openCategories() {
			this.mobileCatActive = !this.mobileCatActive;
		},
		updateValues() {
			this.setChartData('range');
		},
		getRangeData(dataset, catID) {
      const { startDate, endDate } = this.dateRange;
			const processedData = this.processData(dataset, catID);

			const filteredDataset = processedData.filter(d => {
        const currentDate = new Date(d.date);
        return currentDate >= startDate && currentDate <= endDate;
      });
      return filteredDataset;
    },
		toggleDatePicker() {
			const datePicker = document.querySelector('.vue-daterange-picker');
			console.log(datePicker);
		}
  },
	mounted() {
		// const tippyEl = this.$refs.tooltip;
		// tippy(tippyEl, {
		// 	content: 'lorem ipsum'
		// })
	}
}
</script>
