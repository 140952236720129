<template lang="pug">
    header.header(:class="{'menu-active': this.menuIsActive}")
      .header__top
        .header__logo
          .mobile-only
            a(href="https://truflation.com/")
              img.logo-img(src="~@/assets/images/truflation-logo.svg" alt="Truflation Logo")
          .desktop-only
            a(href="https://truflation.com/")
              img.logo-img(src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkBAMAAACCzIhnAAAAFVBMVEUNWMb///+jv+rP3vR3od8ycc9Rh9YdvalIAAAAuUlEQVRYw+3WvQ6CMBSG4fpTZyrKDFV3SbwA5QokIc54/zchBWHmbQLT9848w6GnAWOUUkqp6JrqgZ7fV6VzGSIb13VGpA4kQeQbyB2RFydFIDkipRuaDT6jSNnwoSMbnpFiJCc8PFiZicxfGX/pX5f34GS2bPbQjs0+7X62NIm9Lskq12WdG/ZEpF+wdmFiOTn05GZbStBrjiCWE/MnOT19RuqBkPO3JSam6Ux6hV9X+9YfhlJKqeh+ViYZ+hAyIvMAAAAASUVORK5CYII=" alt="Truflation Logo")
        .header__menu_btn.mobile-only
          button.menu-btn(@click="menuClickHandler", :class="{active: this.menuIsActive}")
            .sr-only Menu
            i.icon.icon-menu
      .header__menu
        p Menu
        ul.dashboard__sidebar_menu
          li
            a.active(href="/")
              i.icon.icon-stats
              | Dashboard
          li
            a(href="https://truflation.com/blog?tag=news")
              i.icon.icon-news
              | News
          li
            a(href="https://truflation.com/methodology")
              i.icon.icon-docs
              | Documentation
</template>
<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      menuIsActive: false
    }
  },
  methods: {
    menuClickHandler(e) {
      this.menuIsActive = !this.menuIsActive;

      const icon = e.target;

      if(this.menuIsActive) {
        icon.classList.remove('icon-menu')
        icon.classList.add('icon-close')
      } else {
        icon.classList.remove('icon-close')
        icon.classList.add('icon-menu')
      }
    }
  }
}
</script>
