<template lang="pug">
	.rate-timeframe
		.rate-timeframe__heading
			h2 Historical Truflation Rate
			ul.rate-timeframe__filters.timeframe-filters
				li
					button.filter-btn.timeframe-filters__filter_btn(@click="filterChart('week')", :class="{active: activeChart === 'week'}") 1W
				li
					button.filter-btn.timeframe-filters__filter_btn(@click="filterChart('month')", :class="{active: activeChart === 'month'}") 1M
				li
					button.filter-btn.timeframe-filters__filter_btn(@click="filterChart('six-months')", :class="{active: activeChart === 'six-months'}") 6M
				li
					button.filter-btn.timeframe-filters__filter_btn(@click="filterChart('year-to-date')", :class="{active: activeChart === 'year-to-date'}") YTD
				li
					button.filter-btn.timeframe-filters__filter_btn(@click="filterChart('year')", :class="{active: activeChart === 'year'}") 1Y
		.rate-timeframe__chart
			svg#trufrate-timeframe
			p.dashboard_disclaimer *All times are UTC

			
</template>

<script>
import * as d3 from "d3";
import enquire from 'enquire.js';
import filterMixin from '../mixins/dataFilters.js';


export default {
	name: 'RateTimeframeComponent',
	mixins: [filterMixin],
	data() {
		return {
			chartData: [],
			activeChart: 'year'
		}
	},
	methods: {
		updateChartData(dataset) {
			const arr = this.processData(dataset);
			this.chartData = arr;
		},
		filterChart(range) {

			if(range === 'week') {
				this.chartData = this.filterData(this.newDataPoints, 7);
				console.log("Chart data: ", this.chartData);
				this.activeChart = 'week';
			} else if (range === 'month') {
				this.chartData = this.filterData(this.newDataPoints, 30);
				this.activeChart = 'month';
			} else if (range === 'six-months') {
				this.chartData = this.filterData(this.newDataPoints, 180);
				this.activeChart = 'six-months';
			} else if (range === 'year-to-date') {
				this.chartData = this.filterData(this.newDataPoints, -1);
				this.activeChart = 'year-to-date';
			} else {
				this.updateChartData(this.newDataPoints);
				this.activeChart = 'year';
			}
		},
		drawChart() {
			let svgWidth = null, svgHeight = null;

			enquire
				.register("screen and (max-width:1023px)", {
					match : function() {
						svgWidth = 600;
						svgHeight = 400;
					},
				})
				.register("screen and (min-width:1024px)", {
					match : function() {
						svgWidth = 600;
						svgHeight = 400;
					},
				})
				.register("screen and (min-width:1280px)", {
					match : function() {
						svgWidth = 800;
						svgHeight = 400;
					},
				})
				.register("screen and (min-width:1536px)", {
					match : function() {
						svgWidth = 800;
						svgHeight = 350;
					},
				})

			let data = this.chartData;
			let margin = { top: 20, right: 0, bottom: 30, left: 32 };
			let width = svgWidth - margin.left - margin.right;
			let height = svgHeight - margin.top - margin.bottom;

			let svg = d3.select('#trufrate-timeframe').attr("viewBox", `0 0 ${svgWidth} ${svgHeight}`);
			svg.selectAll("*").remove();
			// svg.attr("width", svgWidth).attr("height", svgHeight);
			svg.attr("preserveAspectRatio", "xMinYMin meet");
					
			let g = svg.append("g")
					.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

			let x = d3.scaleTime()
					.rangeRound([0, width])
					.nice();

			let y = d3.scaleLinear()
					.rangeRound([height, 0]);

			let line = d3.line()
					.x(function(d) { return x(d.date)})
					.y(function(d) { return y(d.value)})

			x.domain(d3.extent(data, function(d) { return d.date }));
			

			if(this.activeChart === 'week' || this.activeChart === 'month') {
				// y.domain(d3.extent(data, function(d) { return d.value }));
				let extent = d3.extent(data, function(d) { return d.value });
				const diff = extent[1] - extent[0];
				y.domain([extent[0] - diff, extent[1] + diff])
			} else {
				y.domain([0, d3.max(data, function(d) {
					return parseFloat(d.value) + 1; 
				})]);
			}
			
			let xAxis = d3.axisBottom(x).tickSize(0);

			// set up some conditional ticks
			if(this.activeChart === 'week') {
				xAxis.ticks(d3.timeDay.every(1), '%b %e');
			} else if (this.activeChart === 'month') {
				xAxis.ticks(d3.timeDay.filter(d=>d3.timeDay.count(0, d) % 3 === 0), '%b %e');
			} else if(
				this.activeChart === 'six-months' || 
				this.activeChart === 'year' || 
				(this.activeChart === 'year-to-date' && data.length > 90)) {
				xAxis.ticks(d3.timeMonth.every(1), '%b');
			}

			let yAxis = d3.axisLeft(y)
										.ticks(5)
										.tickSize(-width)
										.tickFormat((d) => d + '%');

			g.append("g")
					.attr("transform", "translate(0," + (height + 10) + ")")
					.call(xAxis)
					.select(".domain")
					.remove();

			g.append("g")
				.attr("class", "y-axis")
				.call(yAxis)
				.append("text")
				.attr("fill", "#46608A")
				.attr("y", 6)
				.attr("dy", "0.71em")
				.attr("text-anchor", "end");

			g.append("path")
				.datum(data)
					.attr("fill", "none")
					.attr("stroke", "#0D58C6")
					.attr("stroke-linejoin", "round")
					.attr("stroke-linecap", "round")
					.attr("stroke-width", 1.5)
					.attr("d", line);

			let lg = g.append("defs").append("linearGradient")
				.attr("id", "blue-white-gradient")//id of the gradient
				.attr("x1", "0%")
				.attr("x2", "0%")
				.attr("y1", "0%")
				.attr("y2", "100%")//since its a vertical linear gradient 
				;
				lg.append("stop")
				.attr("offset", "0%")
				.style("stop-color", "#ADDFF7")//end in red
				.style("stop-opacity", 1)

				lg.append("stop")
				.attr("offset", "100%")
				.style("stop-color", "rgba(86, 171, 212, 0)")//start in blue
				.style("stop-opacity", 1)
			
			// Add the area
			g.append("path")
				.datum(data)
				.attr("fill", "url(#blue-white-gradient)")
				.attr("fill-opacity", .3)
				.attr("stroke", "none")
				.attr("d", d3.area()
					.x(function(d) { return x(d.date) })
					.y0( height )
					.y1(function(d) { return y(d.value) })
				)

			this.addChartHover(svg, g, x, y, data, width, height, margin);
		}
	},
	watch: {
		newDataPoints() {
			this.updateChartData(this.newDataPoints);
		},
		chartData() {
			this.drawChart(this.chartData);
		}
	}
}
</script>
