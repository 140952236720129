<template lang="pug">
	.rate-box(class="active")
		LoadingSpinner(v-if="currentTruflationRate === null")
		.rate-box__container(v-else)
			.rate-box__heading
				.rate-box__heading-label
					h2 Truflation YoY Rate
					button.tooltip-btn(ref="tooltip")
						span.sr-only Tooltip
						i.icon.icon-info
				.rate-box__truflation-rate
					p {{ currentTruflationRate }}%
			.rate-box__graph
				.rate-box__graph_bar
					p.min-rate {{ minYTDRate }}%
					p.max-rate {{ maxYTDRate }}%
					span.min-rate-label Lowest YTD
					span.max-rate-label Highest YTD

					.truf-rate-spot(:style="{ left: trufRatePosition + '%' }")
						.dashed-line
							.truf-rate-area
								p.truf-rate {{ currentTruflationRate }}%
								p.truf-date {{ currentDay }}

</template>
<script>
import { mapGetters } from "vuex";
import LoadingSpinner from './LoadingSpinner.vue';
import tippy from 'tippy.js';

export default {
	name: 'RateBoxComponent',
	components: {
		LoadingSpinner
	},
	data() {
		return {
			currentTruflationRate: null,
			maxYTDRate: null,
			minYTDRate: null,
			currentDay: null
		}
	},
	computed: {
		...mapGetters(["newDataPoints"]),
		trufRatePosition() {
			let fieldDifference = this.maxYTDRate - this.minYTDRate;
			return ((this.currentTruflationRate - this.minYTDRate)/fieldDifference) * 100;
		}
	},
	watch: {
		newDataPoints() {
			const wData = this.newDataPoints.w;

			const today = new Date(wData.yoyCur[0]);
			const utcDate = Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), today.getUTCHours(), today.getUTCMinutes(), today.getUTCSeconds());

			let yr = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(utcDate);
			let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(utcDate);
			let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(utcDate);

			this.currentDay = `${day} ${month} ${yr}`;

			this.currentTruflationRate = wData.yoyCur[1].toFixed(2);
			this.maxYTDRate = wData.ytdMax[1].toFixed(2);
			this.minYTDRate = wData.ytdMin[1].toFixed(2);
		},
		currentTruflationRate() {
			if(this.currentTruflationRate !== null) {
				// add timeout as tooltip isn't being selected when immediate
				setTimeout(()=> {
					const tippyEl = this.$refs.tooltip;

					tippy(tippyEl, {
						content: 'Compared to the same day last year'
					})
				}, 1000);
			}	
		}
	}
}
</script>
