<template lang="pug">
  .dashboard
    //- .dashboard__heading
    //-   h1 
    //-     img(src="~@/assets/images/avatar.png" alt="Avatar") 
    //-     | Welcome to your dashboard
      //- language toggle here

    .dashboard__content
      p.dashboard_powered Powered by Truflation
      .dashboard__content_wrapper
        section.dashboard__content_section#rate-timeframe-container
          RateBoxComponent
          RateTimeframeComponent
        section.dashboard__content_section#category-growth-container
          CatTimeframeComponent
        section.dashboard__content_section#rate-key-events-container
          RateKeyEventsComponent
          NewsFeedComponent

</template>
<script>
import RateBoxComponent from './RateBox.vue';
import RateTimeframeComponent from './RateTimeframe.vue';
import CatTimeframeComponent from './CatTimeframe.vue';
import RateKeyEventsComponent from './RateKeyEvents.vue';
import NewsFeedComponent from './NewsFeed.vue';
import { mapGetters } from "vuex";

export default {
  name: 'DashboardComponent',
  created() {
    this.$store.dispatch("fetchData", { self: this });
    this.$store.dispatch("fetchCategories", { self: this });
    this.$store.dispatch("fetchNews", { self: this});
  },
  components: {
    RateBoxComponent,
    RateTimeframeComponent,
    CatTimeframeComponent,
    RateKeyEventsComponent,
    NewsFeedComponent
  },
  computed: {
    ...mapGetters(["dataPoints"]),
  },
}
</script>
