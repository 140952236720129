<template lang="pug">
	.news-feed
		h3 News Feed
		.news-feed__items
			.news-feed__items_item(v-for="item in getFeaturedNews")
				span.news-feed__type(v-if="item.type === 'news'", class="bg-color--blue type-icon") N
				span.news-feed__type(v-if="item.type === 'methodology'", class="bg-color--green type-icon") M
				span.news-feed__type(v-if="item.type === 'data-change'", class="bg-color--yellow type-icon") D
				p.date {{ convertDateString(item.date) }}
				p.desc {{ item.desc }}
				a.link(:href="item.link", ref="noopener", target="_blank") read more 
			
</template>
<script>
import { mapGetters } from "vuex";

export default {
	name: 'NewsFeedComponent',
	computed: {
    ...mapGetters(["getFeaturedNews"]),
  },
	methods: {
		convertDateString(dateString) {
			const newsDate = new Date(dateString);
			
			const utcDate = Date.UTC(newsDate.getUTCFullYear(), newsDate.getUTCMonth(), newsDate.getUTCDate(), newsDate.getUTCHours(), newsDate.getUTCMinutes(), newsDate.getUTCSeconds());

			let yr = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(utcDate);
			let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(utcDate);
			let day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(utcDate);

			return `${month} ${day} ${yr}`;
		}
	}
}
</script>
