<template lang="pug">
	.growth-display(:class="{active: isActive }")
		.growth-display__heading
			p.label 
				| {{ label }}
			span.info
		
		.growth-display__value
			LoadingSpinner(v-if="value === null")
			p(v-else) {{ growthValue }}%   
		
</template>
<script>
import LoadingSpinner from './LoadingSpinner.vue';

export default {
	name: 'GrowthDisplayComponent',
	data() {
		return {
			growthValue: null
		}
	},
	props: {
		label: String,
		isActive: Boolean,
		infoText: String,
		value: Number
	},
	components: {
		LoadingSpinner
	},
	watch: {
		value() {
			let currentValue = parseFloat(this.value);
			let updatedValue = currentValue > 100 ? currentValue.toFixed(0) : currentValue.toFixed(2);
			
			this.growthValue = updatedValue.toString();
		}
	}
}
</script>
