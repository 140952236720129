<template lang="pug">
  #app.app
    .container.app__container
      HeaderComponent
      DashboardComponent
</template>

<script>
import './App.scss';
import DashboardComponent from './components/Dashboard.vue';
import HeaderComponent from './components/Header.vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    DashboardComponent
  }
}
</script>
