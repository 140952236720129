<template lang="pug">
	.rate-key-events
		.rate-key-events__heading
			h3 Truflation Rate vs Key Events
			ul.rate-key-events__legends
				li
					span.rate-key-events__legends_icon.bg-color--blue N
					| News
				li
					span.rate-key-events__legends_icon.bg-color--green M
					| Methodology
				li
					span.rate-key-events__legends_icon.bg-color--yellow D
					| Data Change
		.rate-key-events__chart
			svg#trufrate-key-events
			p.dashboard_disclaimer *All times are UTC
</template>
<script>
import * as d3 from "d3";
import { mapGetters } from "vuex";
import enquire from 'enquire.js';
import filterMixin from '../mixins/dataFilters.js';

export default {
	name: 'RateKeyEventsComponent',
	mixins: [filterMixin],
	data() {
		return {
			chartData: [],
		}
	},
	computed: {
    ...mapGetters(["getNews"]),
  },
	watch: {
		chartData() {
			this.drawChart(this.chartData);
		},
		newDataPoints() {
			this.updateChartData(this.newDataPoints);
		},
	},
	methods: {
		updateChartData(dataset) {
			const arr = this.processData(dataset);
			this.chartData = arr;
		},
		updateNewsData(dataset, newsset) {

			const filteredDataPoints = newsset.map(newsItem => {

				const newsDate = new Date(newsItem.date);
				const filteredData = dataset.filter(d => d.date.getTime() === newsDate.getTime())[0]

				return {
					id: newsItem._id,
					date: newsDate,
					type: newsItem.type,
					value: +filteredData.value.toFixed(2),
					title: newsItem.title
				}
			});
			
			return filteredDataPoints;
		},
		drawChart() {

			let svgWidth = 850, svgHeight = 400;
			
			enquire
				.register("screen and (max-width:1023px)", {
					match : function() {
						svgWidth = 600;
						svgHeight = 400;
					},
				})
				.register("screen and (min-width:1024px)", {
					match : function() {
						svgWidth = 600;
						svgHeight = 500;
					},
				})
				.register("screen and (min-width:1280px)", {
					match : function() {
						svgWidth = 700;
						svgHeight = 400;
					},
				})
				.register("screen and (min-width:1536px)", {
					match : function() {
						svgWidth = 850;
						svgHeight = 400;
					},
				})

			let data = this.chartData;

			let margin = { top: 20, right: 0, bottom: 30, left: 32 };
			let width = svgWidth - margin.left - margin.right;
			let height = svgHeight - margin.top - margin.bottom;

			let svg = d3.select('#trufrate-key-events').attr("viewBox", `0 0 ${svgWidth} ${svgHeight}`);
			svg.selectAll("*").remove();
			// svg.attr("width", svgWidth).attr("height", svgHeight);
			svg.attr("preserveAspectRatio", "xMinYMin meet");
					
			let g = svg.append("g")
					.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

			let x = d3.scaleTime()
					.rangeRound([0, width]);

			let y = d3.scaleLinear()
					.rangeRound([height, 0]);

			const maxY = 	d3.max(data, function(d) {
											return parseFloat(d.value) + 1; 
										})

			let line = d3.line()
					.x(function(d) { return x(d.date)})
					.y(function(d) { return y(d.value)})
					x.domain(d3.extent(data, function(d) { return d.date }));
					y.domain([0, maxY]);

			let xAxis = d3.axisBottom(x).tickSize(0).ticks(d3.timeMonth.every(1), '%b');

			let yAxis = d3.axisLeft(y)
									.ticks(5)
									.tickSize(0)
									.tickFormat((d) => d + '%');

			g.append("g")
				.attr("class", "x-axis")
				.attr("transform", "translate(0," + height + ")")
				.call(xAxis)
				.select(".domain");

			g.append("g")
				.call(yAxis)
				.append("text")
				.attr("fill", "#46608A")
				.attr("transform", "rotate(-90)")
				.attr("y", 6)
				.attr("dy", "0.71em")
				.attr("text-anchor", "end");

			g.append("path")
				.datum(data)
					.attr("fill", "none")
					.attr("stroke", "#0D58C6")
					.attr("stroke-linejoin", "round")
					.attr("stroke-linecap", "round")
					.attr("stroke-width", 1.5)
					.attr("d", line);

			let lg = g.append("defs").append("linearGradient")
				.attr("id", "blue-white-gradient")//id of the gradient
				.attr("x1", "0%")
				.attr("x2", "0%")
				.attr("y1", "0%")
				.attr("y2", "100%")//since its a vertical linear gradient 
				;
				lg.append("stop")
				.attr("offset", "0%")
				.style("stop-color", "#ADDFF7")//end in red
				.style("stop-opacity", 1)

				lg.append("stop")
				.attr("offset", "100%")
				.style("stop-color", "rgba(86, 171, 212, 0)")//start in blue
				.style("stop-opacity", 1)
			
			// Add the area
			g.append("path")
				.attr("class", "gradient")
				.datum(data)
				.attr("fill", "url(#blue-white-gradient)")
				.attr("fill-opacity", .3)
				.attr("stroke", "none")
				.attr("d", d3.area()
					.x(function(d) { return x(d.date) })
					.y0( height )
					.y1(function(d) { return y(d.value) })
				)

			const newsData = this.updateNewsData(data, this.getNews);

			const overlay = g.append("rect")
				.attr("class", "overlay")
				.attr("width", width)
				.attr("height", height)

			const vline = svg.append("line").attr("id", "v_crosshair") // vertical cross hair
				.attr("x1", 0)
				.attr("y1", y(0) + margin.top)
				.attr("x2", 0)
				.attr("y2", y(maxY) + margin.top)
				.style("stroke", "#6984AF")
				.style("stroke-width", "3px")
				.style("stroke-opacity", "0.3")
				.style("display", "none");

			svg.append("line").attr("id", "v_crosshair_selected") // vertical cross hair
				.attr("x1", 0)
				.attr("y1", y(0) + margin.top)
				.attr("x2", 0)
				.attr("y2", y(maxY) + margin.top)
				.style("stroke", "#6984AF")
				.style("stroke-width", "2px")
				.style("stroke-opacity", "1")
				.style("display", "none");

			overlay.on("mouseover", function() { vline.style("display", null); })
						.on("mouseout", function() { vline.style("display", "none"); })
			
			overlay.on("mousemove", function(event) {
				const xCoord = d3.pointer(event)[0] + margin.left;
				
				d3.select("#v_crosshair")
					.attr("x1", xCoord)
					.attr("x2", xCoord)
					.style("display", "block");
			})

			let tg = g.append("g").attr("class","news-labels");

			tg.selectAll("myCircles")
				.data(newsData)
				.join("g")
					.attr("class", "news-label")
					.append("rect")
						.attr("width", d => d.type === "methodology" ? 17 : 15)
						.attr("height", 17)
						.attr("data-id", d => d.id)
						.attr("class", d => "rect-label fill--" + d.type)
						.attr("stroke", "none")
						.attr("x", d => x(d.date))
						.attr("y", d => y(d.value) - 2)
						.attr("r", 3);		
			
			let marker = d3.selectAll(".news-label")
				.append("text").text(d => {
					if(d.type === 'news') return "N"
					else if (d.type === 'methodology') return "M"
					else return "D"
				})
				.attr("x", d => x(d.date) + 2)
				.attr("y", d => y(d.value) + 12)

			const chartBtns = [...document.querySelectorAll('.news-label')];

			let xPos = 0;
			let yPos = 0;

			chartBtns.forEach(btn => {
				btn.addEventListener('click', this.handleNewsClick);

				const rect = btn.querySelector('rect');
				const text = btn.querySelector('text');
				const diffX = Math.abs(xPos - rect.x.baseVal.value);
				const diffY = Math.abs(yPos - rect.y.baseVal.value);

				if(diffX <= 15 && diffY <= 15) {
					rect.y.baseVal.value -= (20 + diffY);
					text.y.baseVal[0].value -= (20 + diffY);
				}
 
				xPos = rect.x.baseVal.value;
				yPos = rect.y.baseVal.value;
			})

			let dateFormat = d3.timeFormat("%B %d, %Y");

			let focus = svg.append("g")
					.attr("class", "focus")
					.style("display", "none");

			let hoverItem = focus.append("foreignObject")
				.attr("width", 160)
				.attr("height", 65)
				.attr("rx", 4)
				.attr("ry", 4);

			let tooltipDiv = hoverItem.append('xhtml:div')
							.attr("class","tooltip-wrap");

			tooltipDiv.append('p')
				.attr('class', 'tooltip-title');

			tooltipDiv.append('p')
				.attr('class', 'tooltip-date');
				
			marker
						.on("mouseout", function() { focus.style("display", "none"); })
						.on("mouseover", function(e, d) {
							e.target;
							focus.style("display", null);
							focus.attr("transform", "translate(" + (x(d.date) - 60) + "," + (y(d.value) - 70) + ")");
							const tooltipDate = focus.select(".tooltip-date");
							tooltipDate.html(dateFormat(d.date));
							focus.select(".tooltip-title").html(d.title);
						})
		},
		handleNewsClick(e) {
			const targetID = e.target.__data__.id

			this.addSelectedLine();

			const featuredNews = [];
			let count = 0, match = false;

			this.getNews.forEach(newsItem => {

				if(match === true && count <= 2) {
					featuredNews.push(newsItem);
					count++;
				}

				if(newsItem._id === targetID) {
					featuredNews.push(newsItem);
					count++;
					match = true;
				}
			});

			this.$store.commit("SET_FEATURED_NEWS", featuredNews);
		},
		addSelectedLine() {
			const hoverLine = d3.select("#v_crosshair");
			const selectLine = d3.select("#v_crosshair_selected");
			selectLine
				.attr("x1", hoverLine.attr("x1"))
				.attr("x2", hoverLine.attr("x2"))
				
			selectLine.style("display", null)
		}
	}
}
</script>
