import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

function getDataUrl(){
    const loc = ""+location
    if (loc.endsWith("t=d"))
	return "/dashboard-data"
    if (loc.endsWith("t=0"))
	return "/dashboard-data0"
    if (loc.endsWith("t=1"))
	return "/dashboard-data1"
    if (loc.endsWith("t=2"))
	return "/dashboard-data2"
    if (loc.endsWith("t=3"))
	return "/dashboard-data3"
    if (loc.endsWith("t=q"))
	return "https://truflation-api-test.hydrogenx.live/dashboard-data"
    return "https://truflation-api.hydrogenx.live/dashboard-data"
}

export const store = new Vuex.Store({
  state: {
    news: [],
    featuredNews: [],
    newDataPoints: []
  },
  mutations: {
    FETCH_NEWS(state, payload) {
      state.news = payload
    },
    SET_FEATURED_NEWS(state, payload) {
      state.featuredNews = payload;
    },
    FETCH_NEWDATA(state, payload) {
      state.newDataPoints = payload;
    }
  },
  getters: {
    dataPoints(state) {
      return state.dataPoints;
    },
    getCategories(state) {
      return state.categories;
    },
    getNews(state) {
      return state.news;
    },
    getFeaturedNews(state) {
      return state.featuredNews;
    },
    newDataPoints(state) {
      return state.newDataPoints;
    }
  },
  actions: {
    fetchData({ commit }) {
     axios.get(getDataUrl())
        .then(response => {
          commit("FETCH_NEWDATA", response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    fetchNews({ commit }) {
      axios.get('/api/news.json')
        .then(function (response) {
          let news = response.data;
          news.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          })

          const featuredNews = news.slice(0,3);

          commit("FETCH_NEWS", news);
          commit("SET_FEATURED_NEWS", featuredNews)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
})
